@import './var.scss';

.#{$namespace}-zoom-in-down-enter-active,
.#{$namespace}-zoom-in-down-leave-active {
  opacity: 1;
  transform: scaleY(1);
  transition: transform 0.3s cubic-bezier(0.23, 1, 0.32, 1),
    opacity 0.3s cubic-bezier(0.23, 1, 0.32, 1);
  transform-origin: center top;
}

.#{$namespace}-zoom-in-down-enter,
.#{$namespace}-zoom-in-down-enter-from,
.#{$namespace}-zoom-in-down-leave-to {
  opacity: 0;
  transform: scaleY(0);
}
