@import './var.scss';

.#{$namespace}-scrollbar {
  height: 100%;
  &:hover {
    .#{$namespace}-scrollbar-track {
      opacity: 1;
    }
  }
}

.#{$namespace}-scrollbar-wrap {
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
}

.#{$namespace}-scrollbar-track {
  position: absolute;
  top: 2px;
  right: 2px;
  bottom: 2px;
  width: 6px;
  z-index: 1;
  border-radius: 4px;
  opacity: 0;
  transition: opacity 0.24s ease-out;
  .#{$namespace}-scrollbar-thumb {
    position: absolute;
    width: 100%;
    height: 0;
    cursor: pointer;
    border-radius: inherit;
    background-color: rgba(144, 147, 153, 0.3);
    transition: background-color 0.3s;
  }
}
