/*------------------------------------
  Avatar Ratio
------------------------------------*/

@use "sass:math";

.avatar.avatar-4by3 {
  width: math.div($avatar-width * 4, 3);
}

.avatar-xss.avatar-4by3 {
  width: math.div($avatar-xss-width * 4, 3);
}

.avatar-xs.avatar-4by3 {
  width: math.div($avatar-xs-width * 4, 3);
}

.avatar-sm.avatar-4by3 {
  width: math.div($avatar-sm-width * 4, 3);
}

.avatar-lg.avatar-4by3 {
  width: math.div($avatar-lg-width * 4, 3);
}

.avatar-xl.avatar-4by3 {
  width: math.div($avatar-xl-width * 4, 3);
}

.avatar-xxl.avatar-4by3 {
  width: math.div($avatar-xxl-width * 4, 3);
}