@import './var.scss';

.#{$namespace}-btn {
  box-sizing: border-box;
  line-height: 1;
  font-size: 14px;
  font-weight: 500;
  padding: 7px 15px;
  margin: 0;
  cursor: pointer;
  background-color: transparent;
  outline: none;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  color: $default-color;
  white-space: nowrap;
  &:hover {
    border-color: $primary-color;
    color: $primary-color;
  }
  &:disabled,
  &.disabled {
    color: $disabled-color;
    cursor: not-allowed;
  }
}

.#{$namespace}-btn-text {
  border: 0;
  padding: 0 4px;
  text-align: left;
  line-height: inherit;
}
