/*------------------------------------
  Button Status Sizes
------------------------------------*/

@use "sass:math";

.btn-xs-status {
  top: -$btn-status-xs-top-offset;
  right: -$btn-status-xs-right-offset;
  font-size: $btn-status-xs-font-size;
  width: $btn-status-xs-width;
  height: $btn-status-xs-height;
  border-width: $btn-xs-status-border-width;

  .btn.rounded-circle & {
    top: -$btn-status-xs-top-offset * 0.25;
    right: -$btn-status-xs-right-offset * 0.25;
  }
}

.btn-sm-status {
  top: -$btn-status-sm-top-offset;
  right: -$btn-status-sm-right-offset;
  font-size: $btn-status-sm-font-size;
  width: $btn-status-sm-width;
  height: $btn-status-sm-height;

  .btn.rounded-circle & {
    top: -$btn-status-sm-top-offset * 0.5;
    right: -$btn-status-sm-right-offset * 0.5;
  }
}

.btn-lg-status {
  top: -$btn-status-lg-top-offset;
  right: -$btn-status-lg-top-offset;
  font-size: $btn-status-lg-font-size;
  width: $btn-status-lg-width;
  height: $btn-status-lg-height;

  .btn.rounded-circle & {
    top: math.div(-$btn-status-lg-top-offset, 1.25);
    right: math.div(-$btn-status-lg-right-offset, 1.25);
  }
}