@use '../docs';
@use '../icon-set/style';

// Bootstrap setup
@import '../../../../node_modules/bootstrap/scss/functions';

// Front setup
@import 'themes/default';

@import 'user-variables';

@import 'front-dashboard/variables';

// Bootstrap core
@import '../../../../node_modules/bootstrap/scss/bootstrap';

/*----------------------------------------------------------------------
  * Front Dashboard Template version v1.0
  * Copyright 2020 Htmlstream
  * Licensed under Bootstrap Themes (https://themes.getbootstrap.com/licenses/)
------------------------------------------------------------------------*/

@import 'front-dashboard/front-dashboard';

// Handsontable
@import '../../../../node_modules/handsontable/dist/handsontable.full.css';

// Custom core
@import 'user';
@import '../../../../node_modules/vue2-datepicker/scss/index.scss';
